import React from 'react';
import { AppBar, Toolbar, Typography,Divider} from '@mui/material';
import HelpModal from "./HelpModal";

function Header({student}) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Password Hunter
        </Typography>
          <HelpModal></HelpModal>
          {student != null &&<>
              <Divider orientation={"vertical"}/>
          <Typography>
              You are Student {student}
          </Typography>
              </>
              }
      </Toolbar>
    </AppBar>
  );
}

export default Header;
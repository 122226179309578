import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const StudentIdPicker = ({student,setStudent}) => {
  // const [student, setStudent] = useState('');

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 30) { // Adjust min and max values as needed
      setStudent(value);
    } else if (e.target.value === '') {
      setStudent('');
    }
  };

  return (
    <TextField
      type="number"
      value={student}
      onChange={handleInputChange}
      placeholder="Enter your Student ID here"
      sx={{ width: '50%' }}
      inputProps={{
        min: 0, // Minimum value
        max: 30 // Maximum value
      }}
    />
  );
};

export default StudentIdPicker;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function HelpModal () {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant={"contained"} onClick={handleOpen}>Help</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
                <List component="nav" aria-label="secondary mailbox folder">

          <ListItemText primary="Install ollama https://ollama.com/" />

          <ListItemText primary="
                      Ollama server should be running, install llama3: ollama pull llama3
          " />
           <ListItemText primary="
Find the right queries to find the passwords          " />
               <ListItemText primary="Send the password to increase your score  " />
                              <ListItemText primary="Enjoy ! " />

      </List>

        </Box>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import ollama from 'ollama/browser'
import { Stepper, Step, StepLabel, Button, Typography,TextField,Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Chat = ({model, student, level, onNext}) => {
  const [message, setMessage] = useState('');
  const [answer, setAnswer] = useState([]);
    const [loading,setLoading] = useState(false);
    const [systemPrompt,setSystemPrompt] = useState("test");
    const [guess,setGuess] = useState(null);

const backUrl="https://cyberlab.famat.me"

const handleChange = (event) => {
setGuess(event.target.value);
};
const sendMessage = (event) =>
{
    if (loading) {
        return;
    }
  event.preventDefault();
      setLoading(true);

  if (systemPrompt && message ) {
      ollama.chat({
          model: model,
          messages: [{role:'assistant',content:systemPrompt},{role: 'user', content: message}],
          metadata:{"num_predict": 500}
      }).then(response => {
          setAnswer(response.message.content);
          setLoading(false);
      });
            setLoading(false);

  }
              setLoading(false);
  };
const sendPassword = () => {
    let chat =  [{role:'assistant',content:systemPrompt},
        {role: 'user', content: message}];

    let data = {
        guessed_password: guess,
        student_id: student,
        lvl: level,
        chat:chat};

    fetch(backUrl + "/submit", {
  method: 'POST',
          mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
})
.then(response => response.json())
.then(data => {
  console.log('Success:', data);
  if (data.message == "Correct"){
        onNext();
        setMessage("");
        setAnswer("");
        setGuess("");
        setSystemPrompt("");
  }
})
.catch((error) => {
  console.error('Error:', error);
});
}
const getSystemPrompt = () => {
    const url = backUrl + '/prompt/' + student + "/" + level;
        fetch(url )
        .then((response) => response.json())
        .then((data) => {
            setSystemPrompt(data.prompt);
        })
        .catch((error) => {
            console.error('Error:', error);

        });
    }
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
useEffect(() => {
    setLoading(true);
    getSystemPrompt();
    sleep(500).then(() => {
        setLoading(false);
    });
}, [level]);
if (loading){
    return (
        <Box sx={{ display: 'flex' }}>
      <CircularProgress />
       </Box>
    );
}
  return (
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50vh',
          width: '80%'
      }}>
          <TextField
              fullWidth
              multiline
      rows={4} // Optional: initial number of rows
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          minHeight: '150px', // Set the minimum height here
        },
      }}
              placeholder="What is your password?"
              type="text"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              disabled={systemPrompt==null}
              onKeyPress={(event) => (event.key === 'Enter' ? sendMessage(event) : null)}
          />
          <Button disabled={loading}
                  onClick={(event) => sendMessage(event)}>Crack</Button>

    {/*         <Box sx={{ display: 'flex' }}>*/}
    {/*  <CircularProgress />*/}
    {/*</Box>*/}
          <TextField fullWidth value={answer} disabled={systemPrompt==null}
        multiline
        placeholder="my password is ..."
        rows={8}
        variant="outlined"
          sx={{
            '& .MuiInputBase-root': {
              minHeight: '150px', // Set the minimum height here
            },
          }}
          />
          <div>
    <TextField value={guess} onChange={handleChange} placeholder="input the password" />
    <Button onClick={()=>sendPassword()} > Submit</Button>
          </div>
      </div>
  );
};

export default Chat;
import React, {useEffect, useState} from 'react';
import { Stepper, Step, StepLabel, Button, Typography,TextField } from '@mui/material';
import Header from './Header'; // Import the Header component
import Chat from './Chat'; // Import the Chat component
import StudentIdPicker from "./StudentIdPicker";
import ollama from 'ollama/browser';


function App() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Welcome','Breezy', 'Piece of Cake', 'Walk in the Park', 'Easy Peasy', 'Getting Interesting', 'Tricky', 'Brain-Teasing',
    'Mind-Boggling', 'Nerve-Wracking', 'Mission Impossible',
  ];
  const prompts= ["welcome","password is 'pass'"]
  const [successStep, setSuccessStep] = useState([]);
  const [goodPassword, setGoodPassword] = useState(false); // Add this line
  const [passwords, setPasswords] = useState([]); // Add this line
  const [student, setStudent] = useState(); // Add this line
  const visibleSteps = steps.slice(activeStep, activeStep + 4); // Change this value to control the number of steps shown

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
  }, []);
  return (
      <div style={{

      }}>
        <Header student={student}/> {/* Use the Header component */}
          <Stepper activeStep={activeStep} alternativeLabel sx={{topPadding:"20px"}}>
              {visibleSteps.map((label, index) => (
                  <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                  </Step>
              ))}
              {activeStep + visibleSteps.length < steps.length &&
                  <Step key={"..."}>
                      <StepLabel>...</StepLabel>
                  </Step>
              }
          </Stepper>

          <div style={{
                        display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
              height: '10vh'
          }}>
           {activeStep == 0 &&
                <StudentIdPicker student={student}
                                 setStudent={setStudent} />
               }
              {activeStep > 0 &&
                  <>
                     <Chat model={"llama3"}
                           student={student}
                           level={activeStep}
                           onNext={handleNext}
                     />

                  </>
              }

          </div>
          <div style={{
                        display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
              height: '130vh'
          }}>

              {activeStep === steps.length ? (
                  <div>
                      <Button onClick={handleBack}>Reset</Button>
                  </div>
              ) : (
                  <div>
                      <Button
                          disabled={activeStep === 0 || activeStep === 1}
                          onClick={handleBack}
                      >
                          Back
                      </Button>
                      <Button disabled={student == null } variant="contained" color="primary" onClick={handleNext}>
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                  </div>
              )}
          </div>
      </div>
  );
}

export default App;